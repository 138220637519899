@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-default {
  background-color: transparent;
}

.navbar-scrolled {
  background-color: #FFF;
  transition: background-color 0.3s ease-in-out; 
}


.bg-hero {
  position: relative;
  background-image: url('/src/assets/img/landingpage/bg.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 110vh;
  overflow-y: visible;
  margin-top: -8rem;
}

.bg-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
}

.bg-stay {
  background-image: linear-gradient(to bottom, #68CCFF, #3483F7);
}

.bg-user {
  background-image: url('/src/assets/img/dashboard/userbg.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-cover {
  background-image: url('/src/assets/img/dashboard/cover.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-ticketing {
  background-image: url('/src/assets/img/landingpage/bg-ticketing.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-quote {
  background-image: url('/src/assets/img/landingpage/bg-quote.webp');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

#booth-img{
  opacity: 0;
  transform: translateX(20px);
}

#booth-img2{
  opacity: 0;
  transform: translateX(-20px);
}

@media (max-width: 767px) {
  #menu {
    display: none;
  }
  .bg-hero {
    margin-top: -9rem;
    background-position: right;
  }
}


@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.animate-zoom {
  animation: zoom 2s infinite;
}

@keyframes pop {
  0% {
    transform: scale(0.5);
  }
  /* 50% {
    transform: scale(1.02);
  } */
  100% {
    transform: scale(1);
  }
}

.animate-pop {
  animation: pop 1s infinite;
}

.animate-pop-once {
  animation: pop 2s;
}

@keyframes floatAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1); 
  }
}

.social-icon {
  opacity: 0;
  transform: translateY(20px);
}

.logo-icon {
  opacity: 0;
  transform: translateY(20px);
}

@keyframes floatingEntrance {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.section {
  animation: floatingEntrance 1s ease forwards;
  opacity: 0;
  margin-bottom: 50px;
}

.hover-text {
  position: absolute;
  top: 0;
  left: 0%;
  color: black;
  padding: 10px;
  border-radius: 5px;
}

.hero-title:first-of-type { 
  animation: reveal 5s infinite;
}

.hero-title:first-of-type { 
  /* margin-left:-355px; */
  animation: slidein 5s infinite;
}

@keyframes showup {
    0% {opacity:0;}
    20% {opacity:1;}
    80% {opacity:1;}
    100% {opacity:0;}
}

@keyframes slidein {
    0% { margin-left:-800px;opacity:0; }
    20% { margin-left:-800px;opacity:0; }
    35% { margin-left:0px;opacity:0; }
    50% { margin-left:0px;opacity:1; }
    100% { margin-left:0px;opacity:1; }
}

@keyframes reveal {
    0% {opacity:0;width:0px;}
    20% {opacity:1;width:0px;}
    30% {width:355px;}
    80% {opacity:1;}
    100% {opacity:0;width:355px;}
}